<template>
    <div class='proxyAudit'>
        <el-dialog class="zc_dialog_box" :visible.sync="dialogVisible" width="624px" :close-on-click-modal='false'>
            <div slot="title">
                <div class="name">代理审核</div>
            </div>
            <div class="inv_main">
                <div class="main_text">
                    <div class="left">
                        <span>*</span>
                        身份
                    </div>
                    <div class="right">
                        <div class="txt">{{ info.type == 1 ? '个人' : '企业' }}</div>
                    </div>
                </div>
                <div class="main_text">
                    <div class="left">
                        <span>*</span>
                        推荐人
                    </div>
                    <div class="right">
                        <div class="txt">{{ info.referrer_mobile || '无' }}</div>
                    </div>
                </div>
                <div class="main_text">
                    <div class="left">
                        <span>*</span>
                        登录手机号
                    </div>
                    <div class="right">
                        <div class="txt">{{ info.mobile }}</div>
                    </div>
                </div>
                <div class="main_text">
                    <div class="left">
                        身份证照片</div>
                    <div class="right">
                        <div class="img_box_con">
                            <div class="img" @click="handleImgPreview(info.idcard_front)">
                                <img :src="info.idcard_front" alt="">
                            </div>
                            <div class="img" @click="handleImgPreview(info.idcard_reverse)">
                                <img :src="info.idcard_reverse" alt="">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="main_text" v-if="info.type == 2">
                    <div class="left">
                        营业执照</div>
                    <div class="right">
                        <div class="img_box_con">
                            <div class="img" @click="handleImgPreview(info.business_license)">
                                <img :src="info.business_license" alt="">
                            </div>

                        </div>
                    </div>
                </div>
                <div class="main_text">
                    <div class="left">
                        补充说明</div>
                    <div class="right">
                        <el-input type="textarea" v-model="info.desc" placeholder="请输入补充说明" maxlength="150"
                            show-word-limit></el-input>
                    </div>
                </div>
                <div class="main_text">
                    <div class="left">
                        注册时间</div>
                    <div class="right">
                        <div class="txt">{{ info.register_time }}</div>
                    </div>
                </div>
                <div class="main_text">
                    <div class="left">
                        提交代理时间</div>
                    <div class="right">
                        <div class="txt">{{ info.create_time }}</div>
                    </div>
                </div>
                <div class="main_text">
                    <div class="left">
                        <span>*</span>
                        有效时间段
                    </div>
                    <div class="right">
                        <el-date-picker size="small" v-model="createper" type="daterange" value-format="yyyy-MM-dd"
                            range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
                        </el-date-picker>
                    </div>
                </div>
            </div>
            <div slot="footer">
                <el-button class="btnBgColor_grey" size="small" @click="refuseBtn">拒绝</el-button>
                <el-button class="btnBgColor_blue" size="small" type="primary" @click="affirmBtn"
                    :loading="subLoading">确定</el-button>
            </div>
        </el-dialog>
        <!-- 图片预览 -->
        <el-dialog :visible.sync="imgPreview" :close-on-click-modal='false'>
            <img width="100%" :src="dialogImageUrl" alt="">
        </el-dialog>
        <!-- 驳回 -->
        <el-dialog class="zc_dialog_box" width="624px" :visible.sync="refuseLayer" :close-on-click-modal='false'>
            <div slot="title">
                <div class="name">代理审核</div>
            </div>
            <div class="inv_main">
                <div class="label">驳回原因：</div>
                <div class="value">
                    <el-input type="textarea" v-model="info.remarks" placeholder="请输入驳回原因" maxlength="20"
                        show-word-limit></el-input>
                </div>
            </div>
            <div slot="footer">
                <el-button class="btnBgColor_grey" size="small" @click="closeBtn">取消</el-button>
                <el-button class="btnBgColor_blue" size="small" type="primary" @click="refuseConfirmBtn"
                    :loading="subLoading">确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>

export default {
    components: {},
    props: {},
    data() {
        return {
            dialogVisible: false,
            subLoading: false,
            id: '',
            info: {},
            // createper: [],
            imgPreview: false, //预览
            dialogImageUrl: '', // 图片预览
            refuseLayer: false, // 驳回
            status: 2, // 审核状态 2：审核通过；3：拒绝
        }
    },
    computed: {
        // 代理有效期
        createper: {
            get() {
                if (JSON.stringify(this.info) == "{}") {
                    return []
                } else {
                    return this.info.agency_started && this.info.agency_ended ? [this.info.agency_started, this.info.agency_ended] : []
                }
            },
            set(val) {
                if (val) {
                    this.info.agency_started = val[0]
                    this.info.agency_ended = val[1]
                } else {
                    this.info.agency_started = ''
                    this.info.agency_ended = ''
                }
            }
        },
    },
    watch: {},
    created() {

    },
    mounted() { },
    methods: {
        // 打开弹框
        openDialogBtn(id) {
            this.dialogVisible = true
            this.id = id
            // this.createper = []
            this.getDetails()
        },
        getDetails() {
            this.loading = true
            this.$userApi.getAgencyApplyDetails(this.id).then(res => {
                this.loading = false
                if (res.code == 1000) {
                    this.info = res.result
                } else {
                    this.$errMsg(res.message)
                }
            })
        },
        // 图片预览
        handleImgPreview(url) {
            this.dialogImageUrl = url;
            this.imgPreview = true;
        },
        // 拒绝
        refuseBtn() {
            this.dialogVisible = false
            this.refuseLayer = true
        },
        // 确定
        affirmBtn() {
            if (!this.createper.length) {
                this.$errMsg('请选择有效时间段')
                return
            }
            // if (!this.info.desc) {
            //     this.$errMsg('请输入补充说明')
            //     return
            // }
            this.status = 2
            this.submit()

        },
        submit() {
            const data = {
                id: this.id,
                remarks: this.info.remarks,
                desc: this.info.desc,
                createper: `${this.createper[0]}|${this.createper[1]}`,
                status: this.status,
            }
            this.subLoading = true
            this.$userApi.agencyApplyAudit(data).then(res => {
                this.subLoading = false
                if (res.code == 1000) {
                    this.$succMsg(res.message)
                    this.dialogVisible = false
                    this.refuseLayer = false

                    this.$emit('refreshList')
                } else {
                    this.$errMsg(res.message)
                }
            })
        },
        closeBtn() {
            this.refuseLayer = false
        },
        refuseConfirmBtn() {
            if (!this.info.remarks) {
                this.$errMsg('请输入驳回原因')
                return
            }
            this.status = 3
            this.submit()
        }
    },
}
</script>

<style lang='scss' scoped>
.proxyAudit {
    .inv_main {
        width: 100%;
        // padding: 10px 30px 0;

        .main_text {
            width: 100%;
            margin-bottom: 20px;
            display: flex;
            align-items: flex-start;

            .left {
                width: 100px;
                text-align: right;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                margin-right: 20px;

                span {
                    padding-right: 5px;
                    color: #E00C25;
                }
            }

            .right {
                width: 420px;

                .el-input {
                    width: 100%;
                }

                .txt {
                    color: #666;
                }

                .img_box_con {
                    display: flex;
                    margin-right: -20px;

                    .img {
                        width: 200px;
                        height: 124px;
                        margin-right: 20px;

                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
            }
        }

        .label {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
        }

        .value {
            margin-top: 16px;
        }
    }
}
</style>
