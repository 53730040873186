<!-- @Author: Yu_Bo -->
<template>
	<div class='proxy_audit'>
		<div class="vue_title">代理审核</div>

		<div class="main">
			<search-params ref="SearchParams" :is_phone="true" :is_name="true" :is_agancy_phone="false"
				:is_user_type="false" :is_identity="true" :user_identity_list="user_identity_list" :is_time_field="true"
				:time_field_list="time_field_list" :is_proxy_audit_status="true"
				:proxy_audit_status_list="proxy_audit_status_list" :is_export='true' @search="search" @export='exportBtn'></search-params>
			<table-list :list="list" :loading="loading" @proxyAuditBtn="proxyAuditBtn"
				@proxyAuditDetailBtn="proxyAuditDetailBtn" @onSortChange="onSortChange" @handleSelectionChange="handleSelectionChange"></table-list>
			<div class="page_box pr20">
				<paging-page :total='total' align='right' @sizeChange='sizeChangeBtn'
					@currentChange='currentChangeBtn'></paging-page>
			</div>
		</div>
		<!-- 代理审核 -->
		<proxy-audit ref="ProxyAudit" @refreshList="getList"></proxy-audit>
		<!-- 代理审核详情 -->
		<proxy-audit-detail ref="ProxyAuditDetail"></proxy-audit-detail>
	</div>
</template>

<script>
import EmptyData from '@/components/empty_data/empty_data.vue'//空数据
import PagingPage from '@/components/paging_page/paging_page.vue'//分页
import SearchParams from '../components/search/index.vue' // 筛选项
import TableList from '../components/tableList/proxyAuditList.vue' // 列表
import ProxyAudit from '../components/proxyAudit/proxyAudit.vue'
import ProxyAuditDetail from '../components/proxyAuditDetail/proxyAuditDetail.vue'
export default {
	components: {
		EmptyData,
		PagingPage,
		SearchParams,
		TableList,
		ProxyAudit,
		ProxyAuditDetail,
	},
	props: {},
	data() {
		return {
			accountType: 2, // 1 个人 2 企业
			btnType: 2, // 1 添加 2 编辑 3 详情
			list: [],
			params: {},
			page: 1,
			limit: 10,
			total: 0,
			loading: false,
			time_field_list: [],
			proxy_audit_status_list: [],
			user_identity_list: [],
			ids: [],
		}
	},
	computed: {},
	watch: {},
	created() {
		this.getParams()
		this.getList()
	},
	mounted() { },
	methods: {
		getParams() {
			this.$userApi.getAgencyApplyListParams().then(res => {
				if (res.code == 1000) {
					this.time_field_list = res.result.select_time
					this.proxy_audit_status_list = res.result.status
					this.user_identity_list = res.result.type
				}
			})
		},
		search(params) {
			this.params.mobile = params.phone
			this.params.keyword = params.name
			this.params.type = params.identity_type
			this.params.time_type = params.time_field
			this.params.time_period = params.date ? `${params.date[0]}|${params.date[1]}` : ''
			this.params.status = params.proxy_audit_status
			this.page = 1
			this.list = []
			this.total = 0
			this.getList()
		},
		resetFrom() {
			this.$refs.SearchParams.resetFrom()
			this.page = 1
			this.list = []
			this.total = 0
			this.params = {}
			this.getList()
		},
		sizeChangeBtn(val) {
			this.limit = val
			this.page = 1
			this.getList();
		},
		currentChangeBtn(val) {
			this.page = val;
			this.getList();
		},
		// 列表排序
		onSortChange(column) {
			console.log(column)
			if (column.order == 'ascending') this.params.order_redirect = 'asc'
			if (column.order == 'descending') this.params.order_redirect = 'desc'
			if (!column.order) this.params.order_redirect = ''
			this.params.order_field = column.prop
			this.getList()
		},
		getList() {
			const data = {
				page: this.page,
				limit: this.limit,
				...this.params,
			}
			this.loading = true
			this.$userApi.getAgencyApplyList(data).then(res => {
				this.loading = false
				if (res.code == 1000) {
					this.list = res.result.data
					this.total = res.result.total
				} else {
					this.$errMsg(res.message)
				}
			})
		},
		// 代理审核
		proxyAuditBtn(id) {
			this.$refs.ProxyAudit.openDialogBtn(id)
		},
		// 代理审核详情
		proxyAuditDetailBtn(id) {
			this.$refs.ProxyAuditDetail.openDialogBtn(id)
		},
		// 导出
		exportBtn(params) {
			var that = this
			var obj = {
				...that.params,
				ids: this.ids.length ? this.ids.join(',') : '',
			}
			that.$userApi.agencyApplyExport(obj).then(res => {
				return
			})
		},
		// 列表勾选
		handleSelectionChange(val) {
			this.ids = val.map(item => item.id)
		}
	},
}
</script>

<style lang='scss' scoped>
@import "./index.scss";

.proxy_audit {
	height: calc(100vh - 270px);

}
</style>