<template>
    <div class='proxyAuditDetail'>
        <el-dialog class="zc_dialog_box" :visible.sync="dialogVisible" width="680px" :close-on-click-modal='false'>
            <div slot="title">
                <div class="name">代理详情</div>
            </div>
            <div class="details_info">
                <div class="info_box">
                    <div class="info_item" v-for="(item, index) in infoList1" :key="index">
                        <div class="label">{{ item.label }}</div>
                        <div class="value">{{ item.value }}</div>
                    </div>
                </div>
                <div class="img_box">
                    <div class="img_box_head">
                        <div class="txt">身份证照片</div>
                    </div>
                    <div class="img_box_con">
                        <div class="img" @click="handleImgPreview(info.idcard_front)">
                            <img :src="info.idcard_front" alt="">
                        </div>
                        <div class="img" @click="handleImgPreview(info.idcard_reverse)">
                            <img :src="info.idcard_reverse" alt="">
                        </div>
                    </div>
                </div>
                <div class="img_box" v-if="info.type == 2">
                    <div class="img_box_head">
                        <div class="txt">营业执照</div>
                    </div>
                    <div class="img_box_con">
                        <div class="img" @click="handleImgPreview(info.business_license)">
                            <img :src="info.business_license" alt="">
                        </div>
                    </div>
                </div>
                <div class="img_box">
                    <div class="img_box_head">
                        <div class="txt">补充说明</div>
                    </div>
                    <div class="img_box_con">
                        <div class="txt">{{ info.desc }}</div>
                    </div>
                </div>
                <div class="img_box" v-if="info.status == 3">
                    <div class="img_box_head">
                        <div class="txt">拒绝原因</div>
                    </div>
                    <div class="img_box_con">
                        <div class="txt">{{ info.remarks }}</div>
                    </div>
                </div>
                <div class="info_box">
                    <div class="info_item" v-for="(item, index) in infoList2" :key="index">
                        <div class="label">{{ item.label }}</div>
                        <div class="value">{{ item.value }}</div>
                    </div>
                </div>
            </div>
        </el-dialog>
        <!-- 图片预览 -->
        <el-dialog :visible.sync="imgPreview" :close-on-click-modal='false'>
            <img width="100%" :src="dialogImageUrl" alt="">
        </el-dialog>
    </div>
</template>

<script>

export default {
    components: {},
    props: {},
    data() {
        return {
            dialogVisible: false,
            tableData: [],
            headerCellStyle: {
                background: '#F0F2F5',
                color: '#333',
                fontWeight: 500,
                fontSize: '14px'
            },
            id: '',
            type: 2, // 1 个人 2 企业
            loading: false,
            imgPreview: false,
            dialogImageUrl: '',
            info: {},
        }
    },
    computed: {
        infoList1() {
            if (JSON.stringify(this.info) == "{}") {
                return {}
            } else {
                // let member = this.info?.member
                // let referrer_member = this.info.referrer_member
                let infoList = [
                    {
                        label: '用户ID：',
                        value: this.info.member_id
                    },
                    {
                        label: '用户名称：',
                        value: this.info.nickname
                    },
                    {
                        label: '身份：',
                        value: this.info.type == 1 ? '个人' : '企业'
                    },
                    {
                        label: '登录手机号：',
                        value: this.info.mobile
                    },
                    {
                        label: '注册来源：',
                        value: this.info.source_name
                    },
                    {
                        label: '推荐人：',
                        value: this.info.referrer_mobile || '无'
                    },
                    {
                        label: '时长余额：',
                        value: this.info.video_duration
                    },
                ]
                return infoList
            }
        },
        infoList2() {
            if (JSON.stringify(this.info) == "{}") {
                return {}
            } else {
                let infoList = [
                    {
                        label: '注册时间：',
                        value: this.info.register_time
                    },
                    {
                        label: '提交代理时间：',
                        value: this.info.create_time,
                    },
                    {
                        label: '有效时间段：',
                        value: this.info.agency_started && this.info.agency_ended ? `${this.info.agency_started}/${this.info.agency_ended}` : '',
                    },
                ]
                return infoList
            }
        },
    },
    watch: {},
    created() { },
    mounted() { },
    methods: {
        // 打开弹框
        openDialogBtn(id) {
            this.dialogVisible = true
            this.id = id
            this.getDetails()
        },
        getDetails() {
            this.loading = true
            this.$userApi.getAgencyApplyDetails(this.id).then(res => {
             this.loading = false
            	if (res.code == 1000) {
            		this.info = res.result
            	} else {
            		this.$errMsg(res.message)	
             }
            })
        },
        // 图片预览
        handleImgPreview(url) {
            this.dialogImageUrl = url;
            this.imgPreview = true;
        },
    },
}
</script>

<style lang='scss' scoped>
.proxyAuditDetail {
    .details_info {
        .info_box {
            display: flex;
            flex-wrap: wrap;

            .info_item {
                display: flex;
                width: 50%;
                margin-bottom: 20px;

                .label {
                    width: 98px;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #666666;
                }

                .value {
                    flex: 1;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                }
            }
        }

        .img_box {
            width: 640px;
            // height: 222px;
            border-radius: 2px;
            border: 1px solid #DCDCDC;
            margin-bottom: 20px;

            .img_box_head {
                height: 42px;
                background: #F0F2F5;
                border-radius: 2px;
                padding: 0 20px;

                .txt {
                    font-size: 14px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #333333;
                    line-height: 42px;
                }
            }

            .img_box_con {
                display: flex;
                padding: 20px;

                .img {
                    width: 220px;
                    height: 140px;
                    margin-right: 20px;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                .txt {
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    line-height: 20px;
                }
            }
        }
    }
}
</style>
