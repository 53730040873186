<!-- @Author: Yu_Bo -->
<template>
    <div class=''>
        <div class="table_wrap">
            <el-table ref="table1" key="table1" :data="list" v-loading="loading" @sort-change="onSortChange"
                @selection-change="handleSelectionChange" style="width: 100%" header-cell-class-name="table-header"
                cell-class-name="cell-class">
                <template slot="empty">
                    <empty-data img='no_img_b.png' text='暂无数据'></empty-data>
                </template>
                <el-table-column type="selection" width="55" fixed="left">
                </el-table-column>
                <el-table-column prop="member_id" label="用户ID" width="85" fixed="left">
                </el-table-column>
                <el-table-column prop="mobile" label="登录手机账号" width="125" fixed="left">
                </el-table-column>
                <el-table-column prop="" label="用户名称" width="145" fixed="left">
                    <template slot-scope="scope">
                        <div class="flex_box">
                            <div v-if="scope.row.type == 1" class="isPerson">个人</div>
                            <div v-if="scope.row.type == 2" class="isCompony">企业</div>
                            <div class="flex_box">{{ scope.row.nickname }}</div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="source_name" label="注册来源" width="125">
                </el-table-column>
                <el-table-column prop="video_duration" label="时长余额" width="125"></el-table-column>
                <el-table-column prop="register_time" label="注册时间" width="105" sortable="custom">
                </el-table-column>
                <el-table-column prop="create_time" label="提交代理时间" width="165" sortable="custom">
                </el-table-column>
                <el-table-column prop="" label="审核状态" width="85">
                    <template slot-scope="scope">
                        <div v-if="scope.row.status == 1" class="txt_warning">待审核</div>
                        <div v-if="scope.row.status == 2" class="txt_success">已通过</div>
                        <div v-if="scope.row.status == 3" class="txt_error">已拒绝</div>
                    </template>
                </el-table-column>
                <el-table-column prop="audit_time" label="审核时间" min-width="105" sortable="custom">
                </el-table-column>
                <el-table-column label="操作" fixed="right" width="105">
                    <template slot-scope="scope">
                        <el-button v-if="scope.row.status == 1" type="text" size="small"
                            @click="proxyAuditBtn(scope.row.id)">
                            审核
                        </el-button>
                        <el-button type="text" size="small" @click="proxyAuditDetailBtn(scope.row.id)">
                            详情
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>

    </div>
</template>

<script>
import EmptyData from '@/components/empty_data/empty_data.vue'//空数据

export default {
    components: {
        EmptyData,
    },
    props: {
        list: {
            type: Array,
            default: () => []
        },
        loading: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {

        }
    },
    computed: {

    },
    watch: {},
    created() { },
    mounted() { },
    methods: {
        // 代理审核
        proxyAuditBtn(id) {
            this.$emit('proxyAuditBtn', id)
        },
        // 代理审核详情
        proxyAuditDetailBtn(id) {
            this.$emit('proxyAuditDetailBtn', id)
        },
        // 列表排序
        onSortChange(column) {
            this.$emit('onSortChange', column)
        },
        // 列表筛选
        handleSelectionChange(val) {
            this.$emit('handleSelectionChange', val)
        },
    },
}
</script>

<style lang='scss' scoped>
@import "./index.scss";
</style>